



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DataTableDeCrud from './DataTableDeCrud.vue';
import { DataOptions } from 'vuetify';
import Axios, { CancelTokenSource, CancelToken } from 'axios';
import { Page } from '@/models';
import { Pageable } from '@/models/Pageable';
import { sortConverter } from '@/shareds/paginacao';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';

@Component({
	components: {
		DataTableDeCrud,
	},
})
export default class DataTableDeCrudPaginado extends Vue {
	@Prop({ type: Function }) onBuscarPagina!: (pageable: Pageable, parametros: any, cancelToken: CancelToken) => Promise<Page<any>>
	@Prop({ type: Object, default: null }) filtroInicial!: any

	options: DataOptions | null = null
	totalDeElementos = 0

	carregando = false
	cancelToken: CancelTokenSource | null = null

	paginavel: Pageable = obterPaginavelVazio()
	pagina: Page<any> = obterPaginaVazia()
	parametros: any = this.filtroInicial

	@Watch('options')
	onChangeOptions(options: DataOptions) {
		this.buscar({
			page: options.page -1,
			size: options.itemsPerPage,
			sort: sortConverter(options),
		})
	}

	get listeners() {
		const { input, change, ...listeners } = this.$listeners
		return listeners
	}

	async buscar(paginavel: Pageable) {
		try {
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = Axios.CancelToken.source()
			this.carregando = true

			const pagina = await this.onBuscarPagina(
				paginavel,
				this.parametros,
				this.cancelToken.token,
			)
			
			this.totalDeElementos = pagina.totalElements
			this.carregando = false
		} catch (error: any) {
			if (Axios.isCancel(error)) return
			AlertModule.setError(error)
			this.pagina = obterPaginaVazia()
			this.totalDeElementos = 0
			this.carregando = false
			throw error
		}
	}

	reiniciar(parametros: any = null) {
		this.parametros = parametros
		if (!this.options) return
		this.options = {
			...this.options,
			page: 1,
		}
	}
	
}

function obterPaginavelVazio(): Pageable {
	return {
		page: 0,
	}
}

function obterPaginaVazia(): Page<any> {
	return {
		content: [],
		last: true,
		first: true,
		totalPages: 0,
		totalElements: 0,
		size: 0,
		number: 0,
		numberOfElements: 0,
		empty: true,
		sort: {
			empty: true,
			sorted: false,
			unsorted: true,
		},
	}
}

